import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  grid-column: auto / span 12;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.h2<{ $size?: string }>`
  font-size: ${({ $size }) => ($size === 'large' ? '3rem' : '1.75rem')};
  font-weight: 900;
  line-height: 1;
`;

export const SubGrid = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  row-gap: 0;
  max-width: calc(100vw - ${spacing.space48});

  @media (min-width: ${breakpoints.m}) {
    gap: ${spacing.space24};
    row-gap: 0;
  }

  @media (min-width: ${breakpoints.l}) {
    gap: ${spacing.space32};
    row-gap: 0;
  }
`;
