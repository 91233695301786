import React, { useEffect } from 'react';
import qs from 'qs';

import { Grid } from '@naf/grid';
import { useDispatch } from 'react-redux';
import useSelector from '../../../../redux/typedHooks';
import { Filters } from './components/Filters';
import { FixedResults } from './components/FixedResults';
import { TimeLimitedResults } from './components/TimeLimitedResults';
import { SearchInput } from './components/SearchInput';
import { MembershipBenefitSearchFilterType } from '../../../../../../types/MembershipBenefitSearchFilterType';
import { getURLParams } from '../../../../lib/getUrlParams';
import { actions } from '../../../../redux/modules/membershipBenefitSearch';
import { BackToTop } from '../../../BackToTop/BackToTop';
import { useFetchMembershipBenefits } from '../../../../hooks/useFetchMembershipBenefits';

export const MembershipBenefitListBlock = () => {
  const { apimBaseUrl, apimContentHub, apimNafNoApi } = useSelector((state) => state.application);
  const searchState = useSelector((state) => state.membershipBenefitSearch);
  const dispatch = useDispatch();
  const { updateAllBenefits } = useFetchMembershipBenefits();

  useEffect(() => {
    if (searchState.isFirstRender) {
      const params: Record<string, any> = getURLParams();
      dispatch(actions.setState({ partialState: params }));
    }
  }, [dispatch, searchState.isFirstRender]);

  useEffect(() => {
    if (!(apimBaseUrl && apimContentHub && apimNafNoApi)) return;

    const debounceTimer = setTimeout(async () => {
      try {
        const queryParams: {
          SearchInput?: string;
          Tags?: MembershipBenefitSearchFilterType['slug'][];
        } = {};

        if (searchState.query) queryParams.SearchInput = searchState.query;
        if (searchState.tags) queryParams.Tags = searchState.tags;

        const queryString = qs.stringify(queryParams, { arrayFormat: 'comma' });
        const oldUrlState = qs.parse(window?.location.search, { ignoreQueryPrefix: true });
        const urlStateString = qs.stringify(
          {
            ...oldUrlState,
            tags: searchState.tags,
            query: searchState.query,
          },
          { arrayFormat: 'comma' },
        );

        if (!window?.location.search) {
          window?.history.pushState({}, '', `${window?.location.pathname}?${urlStateString}`);
        } else {
          window?.history.replaceState({}, '', `${window?.location.pathname}?${urlStateString}`);
        }

        updateAllBenefits(queryString);
      } catch (err) {
        dispatch(actions.setError({ error: err }));
      }
    }, 250);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(debounceTimer);
  }, [apimBaseUrl, apimContentHub, apimNafNoApi, dispatch, updateAllBenefits, searchState.query, searchState.tags]);

  return (
    <Grid>
      <SearchInput value={searchState.query} />
      <Filters
        tags={searchState.tags}
        availableTags={searchState.availableTags}
        countTotalItemsInTextSearch={searchState.countTotalItemsInTextSearch}
      />
      <TimeLimitedResults />
      <FixedResults />
      <BackToTop />
    </Grid>
  );
};
