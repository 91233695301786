import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useSelector from '../redux/typedHooks';
import { actions } from '../redux/modules/membershipBenefitSearch';

export const useFetchMembershipBenefits = () => {
  const dispatch = useDispatch();
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = useSelector((state) => state.application);

  const runFetch = useCallback(
    async (params: string) => {
      try {
        if (apimContentHub && apimNafNoApi)
          return await fetch(`${apimBaseUrl}/${apimNafNoApi}/benefits?${params}`, {
            headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
          });
        return null;
      } catch (e) {
        return null;
      }
    },
    [apimBaseUrl, apimContentHub, apimNafNoApi],
  );

  const getTags = useCallback(
    async (queryString: string) => runFetch(`${queryString}&HideItems=true&applyOrder=true`),
    [runFetch],
  );

  const getCampaignBenefits = useCallback(
    async (queryString: string) =>
      runFetch(`${queryString}&Take=100&page=0&HasActiveCampaign=true&ShowTagInfo=false&applyOrder=true`),
    [runFetch],
  );

  const getFixedBenefits = useCallback(
    async (queryString: string) =>
      runFetch(`${queryString}&Take=100&page=0&HasActiveCampaign=false&ShowTagInfo=false&applyOrder=true`),
    [runFetch],
  );

  const updateAllBenefits = useCallback(
    async (queryString: string) => {
      const [tagsResponse, activeResponse, inactiveResponse] = await Promise.all([
        getTags(queryString),
        getCampaignBenefits(queryString),
        getFixedBenefits(queryString),
      ]);

      const [tags, dataActive, dataInactive] = await Promise.all([
        tagsResponse?.json(),
        activeResponse?.json(),
        inactiveResponse?.json(),
      ]);

      const combinedItems = [...(dataActive?.items ?? []), ...(dataInactive?.items ?? [])];
      const total = (dataActive?.total ?? 0) + (dataInactive?.total ?? 0);
      const countTotalItemsInTextSearch = tags?.countTotalItemsInTextSearch ?? 0;
      const availableTags = tags?.availableTags ?? [];

      dispatch(
        actions.update({
          items: combinedItems,
          skip: 0,
          total,
          countTotalItemsInTextSearch,
          availableTags,
        }),
      );
    },
    [dispatch, getCampaignBenefits, getFixedBenefits, getTags],
  );

  return { updateAllBenefits };
};
