import { Chips } from '@naf/chips';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { GridCol } from '@naf/grid';
import { breakpoints, spacing } from '@naf/theme';
import { useDispatch } from 'react-redux';
import { MembershipBenefitSearchFilterType } from '../../../../../../../types/MembershipBenefitSearchFilterType';
import { actions } from '../../../../../redux/modules/membershipBenefitSearch';

interface Props {
  availableTags?: MembershipBenefitSearchFilterType[];
  tags?: MembershipBenefitSearchFilterType['slug'][];
  countTotalItemsInTextSearch: number;
}

export const Filters = ({ availableTags, tags, countTotalItemsInTextSearch }: Props) => {
  const dispatch = useDispatch();

  const sortedFilters = useMemo(
    () =>
      [...availableTags].sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      }),
    [availableTags],
  );

  return sortedFilters ? (
    <GridCol s="12" m="12" l="12" xl="12">
      <FiltersWrapper>
        {sortedFilters ? (
          <Chips
            icon={tags.length === 0 ? 'checked' : undefined}
            onClick={() => {
              dispatch(actions.clearTags());
            }}
            variant={tags.length === 0 ? 'signature40' : undefined}
            ariaLabel="Alle fordeler"
            text={`Alle fordeler (${countTotalItemsInTextSearch})`}
            key="total"
          />
        ) : null}
        {sortedFilters?.map((tag) => (
          <Chips
            icon={tags.includes(tag.slug) ? 'checked' : undefined}
            onClick={() => {
              dispatch(actions.selectTag({ tag }));
            }}
            variant={tags.includes(tag.slug) ? 'signature40' : undefined}
            ariaLabel={tag.title}
            text={`${tag.title} (${tag.count})`}
            key={tag.id}
          />
        ))}
      </FiltersWrapper>
    </GridCol>
  ) : null;
};

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  @media (max-width: ${breakpoints.m}) {
    max-width: calc(100vw - ${spacing.space48});
    margin-bottom: ${spacing.space8};
  }
`;
