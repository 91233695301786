import { Transition, TransitionGroup } from 'react-transition-group';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { AnimationWrapper } from '../../../../skeleton-loaders/AnimationWrapper';
import { MembershipBenefitReference } from '../../../../../../../types/membershipBenefitReferenceType';
import BenefitCard from '../../../../BenefitCard/BenefitCard';
import { BenefitLoader } from '../../../../skeleton-loaders/BenefitLoader';

const placeholderList = [...Array(12).keys()];

export const ResultPage = ({
  page,
}: {
  page: { data: MembershipBenefitReference[]; meta: { isLoading: boolean } };
}) => {
  const placeholderGridRef = useRef<HTMLDivElement | null>(null);
  const gridRef = useRef<HTMLDivElement | null>(null);

  return (
    <AnimationGrid>
      {page.meta.isLoading && (
        <Transition
          nodeRef={placeholderGridRef}
          in={page.meta.isLoading}
          timeout={{ enter: 250, exit: 0 }}
          unmountOnExit
          mountOnEnter
        >
          {(state) => (
            <StyledAnimationWrapper ref={placeholderGridRef} $state={state}>
              {placeholderList.map((i) => (
                <BenefitLoader key={`article-placeholder-item-${i}`} />
              ))}
            </StyledAnimationWrapper>
          )}
        </Transition>
      )}
      {!page.meta.isLoading && (
        <Transition nodeRef={gridRef} key="data" in={!page.meta.isLoading} timeout={250} unmountOnExit mountOnEnter>
          {(state) => (
            <StyledAnimationWrapper ref={gridRef} $state={state}>
              {Object.keys(page.data).length > 0
                ? page.data.map((item) => <BenefitCard benefit={item} key={`item-${item.id}`} />)
                : null}
            </StyledAnimationWrapper>
          )}
        </Transition>
      )}
    </AnimationGrid>
  );
};

const AnimationGrid = styled(TransitionGroup)`
  grid-column: 1 / -1;
`;

const StyledAnimationWrapper = styled(AnimationWrapper)`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.space40} ${spacing.space24};

  @media (max-width: ${breakpoints.m}) {
    gap: ${spacing.space32} ${spacing.space24};
    justify-content: center;
  }
`;
