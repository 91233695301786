import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import { nafColor } from '@nafcore/theme';

export const BenefitLoader = () => (
  <Wrapper>
    <ContentLoader
      uniqueKey="article-loader"
      width="353px"
      height="414px"
      viewBox="0 0 353 414"
      preserveAspectRatio="xMidYMid meet"
      backgroundColor={nafColor.neutral.neutral1}
      foregroundColor={nafColor.neutral.neutral2}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="190" />
      <rect x="20" y="238" rx="3" ry="3" width="60%" height="30" />
      <rect x="20" y="278" rx="3" ry="3" width="50%" height="30" />
      <rect x="20" y="318" rx="3" ry="3" width="55%" height="30" />
      <rect x="20" y="440" rx="4" ry="4" width="50%" height="30" />
    </ContentLoader>
  </Wrapper>
);

const Wrapper = styled.div`
  overflow: hidden;
  height: auto;
  border: 1px solid ${nafColor.neutral.neutral1};
`;
