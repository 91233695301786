import React from 'react';
import { spacing } from '@naf/theme';
import { ResultPage } from './ResultPage';
import useSelector from '../../../../../redux/typedHooks';
import { MembershipBenefitReference } from '../../../../../../../types/membershipBenefitReferenceType';
import { ModularButton } from '../../../../ModularButton/ModularButton';
import * as S from './Styles';

export const FixedResults = () => {
  const { items: pages } = useSelector((state) => state.membershipBenefitSearch);

  const filteredPages: Record<number, { data: MembershipBenefitReference[]; meta: { isLoading: boolean } }> =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(pages).map(([key, page]) => ({
      ...page,
      data: page.data?.filter(
        (item) => !item.campaignDetails?.endDate || new Date(item.campaignDetails?.endDate) < new Date(),
      ),
    }));

  return (
    <>
      <S.HeaderWrapper>
        <S.Header>
          <S.Title>Faste rabatter</S.Title>
        </S.Header>
      </S.HeaderWrapper>
      {Object.values(filteredPages).length !== 0 ? (
        Object.entries(filteredPages).map(([key, page]) => (
          <S.SubGrid key={key}>
            <ResultPage page={page} />
          </S.SubGrid>
        ))
      ) : (
        <S.SubGrid key="emptyResult">
          <ResultPage page={{ data: [], meta: { isLoading: true } }} />
        </S.SubGrid>
      )}
      <ModularButton
        primaryButton={{
          buttonText: 'Se medlemskapene våre',
          variant: 'secondary',
          externalLink: {
            href: '/medlemskap',
            blank: false,
            key: 'medlemskap-link2',
            title: 'Medlemskap',
            type: 'link',
            style: {
              whiteSpace: 'nowrap',
              display: 'inline-flex',
              marginTop: spacing.space24,
            },
          },
        }}
        secondaryButton={{
          buttonText: 'Se de unike NAF-fordelene',
          externalLink: {
            href: '/medlemskap/medlemsfordeler',
            blank: false,
            key: 'medlemskapfordeler-link',
            title: 'Medlemskapsfordeler',
            type: 'link',
            style: {
              whiteSpace: 'nowrap',
              display: 'inline-flex',
              marginTop: spacing.space24,
            },
          },
        }}
      />
    </>
  );
};
