import React from 'react';
import { ResultPage } from './ResultPage';
import useSelector from '../../../../../redux/typedHooks';
import { MembershipBenefitReference } from '../../../../../../../types/membershipBenefitReferenceType';
import * as S from './Styles';

export const TimeLimitedResults = () => {
  const { items: pages, query } = useSelector((state) => state.membershipBenefitSearch);

  const filteredPages = Object.entries(pages).reduce((acc, [key, page]) => {
    const filteredData =
      page.data?.filter(
        (item) => item.campaignDetails?.endDate && new Date(item.campaignDetails.endDate) > new Date(),
      ) || [];

    acc[key] = { ...page, data: filteredData };
    return acc;
  }, {} as Record<string, { data: MembershipBenefitReference[]; meta: { isLoading: boolean } }>);

  const hasResults = Object.values(filteredPages).some((page) => page.data && page.data.length > 0);

  if (!query && !hasResults) {
    return null;
  }

  return (
    <>
      <S.HeaderWrapper>
        <S.Header>
          <S.Title $size="large">Tidsbegrensede kampanjer</S.Title>
        </S.Header>
      </S.HeaderWrapper>
      {hasResults ? (
        Object.entries(filteredPages).map(([key, page]) => (
          <S.SubGrid key={key}>
            <ResultPage page={page} />
          </S.SubGrid>
        ))
      ) : (
        <S.SubGrid key="emptyResult">
          <h3>Fant ingen tidsbegrensede kampanjer</h3>
        </S.SubGrid>
      )}
    </>
  );
};
